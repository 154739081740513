@import '../../assets/scss/variable.scss';


.sidebar {
    width: 280px !important;
    min-width: 280px !important;
    border-right: unset !important;

    @media (max-width:1366px) {
        min-width: 220px !important;
        width: 220px !important;
    }

    &.ps-collapsed {
        width: 95px !important;
        min-width: 95px !important;

        @media (max-width: 1920px) and (max-height: 600px) {
            .logo-header {
                width: 95px !important;
            }
        }

        @media (max-width:1366px) {
            width: 80px !important;
            min-width: 80px !important;

            .logo-header {
                width: 80px !important;
            }
        }
    }


    .pill-item {
        border-radius: 15px;
        padding: 16px 20px;
        background-color: #F9F9F914;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        flex-direction: column;
        margin-left: 20px;
        margin-right: 20px;

        .logout-btn {
            padding: 5px 10px;
            min-height: 35px;
            font-size: 14px;
            font-weight: 600;
        }
    }

    &.ps-toggled {
        width: 250px !important;
        left: 0 !important;
    }


    .profile-pill {
        height: 76px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // overflow: hidden;
        // text-overflow: ellipsis;

        .profile-name {
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            color: #DDDFE0;
            margin-bottom: 0;
        }

        .profile-role {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #878C90;
            margin-bottom: 0;
        }
    }

    .general-item {
        padding-top: 40px;
        padding-left: 32px;

        .general-text {
            font-size: 12px;
            font-weight: 700;
            line-height: 18px;
            color: #4D5661;
            text-transform: uppercase;
            margin-bottom: 18px;
        }
    }

    .sidebar-item {
        padding-left: 45px;

        .item-container {
            text-decoration: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 25px;
            padding: 13px 0;

            .image-wrapper {
                min-width: 28px;
                min-height: 28px;
            }
        }

        .sidebar-text {
            text-decoration: none;
            margin-bottom: 0;
            color: #FFFFFF99;
            font-size: 16px;
            line-height: 22px;
            font-weight: 600;
        }

        .active {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                background-color: #C7322E;
                height: 8.5px;
                width: 8.5px;
                border-radius: 50px;
                top: 22px;
                left: -20px;
            }
        }

        .sidebar-text-active {
            text-decoration: none;
            color: #FFFFFF;
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            margin-bottom: 0;
        }

        @media (max-width:1366px) {
            padding-left: 25px;
        }
    }

    .tiger-container {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            opacity: 0.5;
        }
    }

    .ps-sidebar-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100vh;
        max-height: 100%;
        overflow-y: auto;
        padding: 25px 0 0 0;
        background-color: #091523;
        width: 100%;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .top-menu,
    .bottom-menu {
        width: 100%;

        .css-vj11vy {
            .logo-header {
                padding-bottom: 30px;
            }
        }

        .logo-wrapper {
            display: flex;
            align-items: center;
            padding-left: 20px;
        }
    }
}