.language-tabs {
    width: 189px;
    height: 104px;
    background-color: #091523;
    border-radius: 15px;
    // position: absolute;
    // top: -71px;
    z-index: -1;

    .language-parent {
        display: flex;
        align-items: center;
        padding: 13px 24px;
        justify-content: space-between;

        .text {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #878C91;
            cursor: pointer;
        }

        .text-selected {
            color: #FFFFFF;
            position: relative;
            cursor: pointer;

            &::before {
                content: ' ';
                position: absolute;
                bottom: -11px;
                background: #FED200;
                border-radius: 1px;
                min-width: 100%;
                height: 2px;
            }
        }
    }
}