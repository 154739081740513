.modal-content {
    background: #FFFFFF !important;
    box-shadow: -40px 40px 80px -8px rgba(145, 158, 171, 0.24) !important;
    border-radius: 20px !important;
    border: none !important;
}

.modal-container {

    .close-icon-container {
        display: flex;
        justify-content: flex-end;

        .modal-close-icon {
            cursor: pointer;
        }
    }

    .header-container {
        .title {
            font-weight: 600;
            font-size: 22px;
            line-height: 36px;
            color: #091523;
            text-align: center;
        }
    }

    .input-container-video {
        margin: 30px 60px 10px 60px;
        display: flex;
        flex-direction: column;
        // gap: 24px;

        .question-textarea{
            margin-top: 16px;
        }
        .add-btn{
            padding: 0px 16px;
            font-size: 18px;
            font-weight: 600;
            min-height: 42px;
            border-radius: 15px;
            margin-left: auto;
        }

        .input-video-margin {
            margin-bottom: 0;
        }
    }

    .button-container {
        margin: 30px 60px;
    }
}

.modal-backdrop.show {
    background: rgba(9, 21, 35, 0.4) !important;
    opacity: 1 !important;
    backdrop-filter: blur(2px) !important;
}


.upload-input {
    display: none;
}
// .upload-input[type=file]::before {
//     content: attr(placeholder);
//     color: #aaa;
//   }
.label-tag {
    cursor: pointer;
    width: 100%;
}
.upload-file {
    position: relative;
    background-color: #DDDFE0;
    padding: 0px 10px;
    height: 100px;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    border-radius: 15px;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
    p {
        margin: 0;
        padding-top: 9px;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #4D5661;
        mix-blend-mode: normal;
        opacity: 0.5;
    }
}
.file-error {
    color: red !important;
}