.subscriptions-section {
    background-image: url(../../../assets/images/onboarding-bg.png);
    height: 100vh;
    background-color: #F9F9F9;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-height: 900px) {
        padding: 20px 0;
    }

    .main-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }


    .subscriptions-container {
        background-color: #FFFFFF;
        padding: 24px 24px 34px;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
        border-radius: 15px;
        width: 1120px;
        max-width: 100%;

        @media (max-height: 900px) {
            max-height: 800px;
            overflow-y: auto;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @media (max-width: 1200px) {
            max-width: 90%;
        }

        .logo-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .heading-safety {
                font-family: 'Open Sans';
                color: #091523;
                font-size: 24px;
                line-height: 34px;
                font-weight: 700;
                padding-top: 20px;
                padding-bottom: 15px;
                text-align: center;
                margin-bottom: 0;

                .red {
                    font-family: "Oswald";
                    color: #C7322E;
                    display: block;
                }
            }

            .sub-heading {
                color: #4D5661;
                font-weight: 600;
            }
        }

        .subscriptions-card-wrapper {

            @media (max-width: 1200px) {
                flex-direction: column;
            }

            .EditplanBtn {
                .switch-label {
                    display: none;
                }

                .board-btn {
                    width: 100%;
                }
            }
            .slick-list{
                .slick-track{
                    display: flex !important;
                    gap: 10px;
                }
            }

            .slick-next {
                right: -14px !important;
                &::before {
                    content: url('../../../assets/images/chevronforward.svg') !important;
                }
            }
            .slick-prev {
                display: block !important;
                left: -8px !important;
                transform: rotate(180deg);
                &::before {
                    content: url('../../../assets/images/chevronforward.svg') !important;
                }
            }
        }

        .button-wrapper {
            width: 100%;
            padding-top: 20px;
            .board-btn {
                max-width: 480px;
                width: 100%;
            }
        }

    }
}