.onboarding-page-section {
    background-image: url(../../../assets/images/onboarding-bg.png);
    height: 100vh;
    background-color: #F9F9F9;
    background-repeat: no-repeat;
    background-size: cover;

    .onboarding-content-wrapper {
        width: 780px;
        max-width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        height: 100%;

        // @media (max-width: 900px) {
        //     width: 100%;
        // }

        .onboarding-slide-wrapper {
            display: flex;
            height: 100%;
            max-height: 670px;

            // @media (max-width: 900px) {
            //     width: 100%;
            // }

            @media (max-height: 900px) {
                max-height: 570px;
            }

            @media (max-height: 700px) {
                max-height: 470px;
            }

            .onboarding-slide-left {
                background-color: #091523;
                padding: 60px 40px 52px 39px;
                color: #FFFFFF;
                text-align: center;
                max-width: 390px;
                border-radius: 20px;
                position: relative;
                z-index: 1;

                // @media (max-width: 900px) {
                //     width: 50%;
                //     border-radius: 20px 0px 0px 20px;
                //     max-width: 50%;
                // }

                @media (max-height: 700px) {
                    padding: 50px 30px;
                }

                .details-wrapper {
                    h1 {
                        font-size: 26px;
                        font-weight: 600;
                        margin-bottom: 30px;
                    }

                    p {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 24px;
                        max-width: 95%;
                        margin: 0 auto 30px;

                        @media (max-height: 700px) {
                            font-size: 16px;
                            margin: 0 auto 20px;
                        }

                        .yellow {
                            color: #FED200;
                            margin-left: 3px;
                        }
                    }
                    // @media (max-width: 900px) {
                    //     max-height: 100%;
                    //     overflow-y: auto;
                    // }
                }

                .actions-wrapper {
                    .dots-wrapper {
                        .dot {
                            height: 6px;
                            width: 6px;
                            border-radius: 50%;
                            background-color: #fff;
                            cursor: pointer;

                            &.active {
                                width: 30px;
                                height: 4px;
                                pointer-events: none;
                                border-radius: 2px;
                            }

                            &:nth-of-type(2) {
                                margin: 0 6px;
                            }
                        }
                    }

                    .next {
                        color: #FED200;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }

            }

            .onboarding-slide-right {
                width: 467px;
                max-width: 100%;
                background-image: url(../../../assets/images/onboarding-lion1-bg.png);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                margin-left: -77px;
                background-color: #fff;
                border-radius: 20px;
                text-align: center;
                padding: 47px 10px 29px;

                // @media (max-width: 900px) {
                //     width: 50%;
                //     border-radius: 0px 20px 20px 0px;
                //     margin-left: unset;
                // }

                &.training-slide-right {
                    background-image: url(../../../assets/images/onboarding-lion2-bg.png);
                }

                &.employee-slide-right {
                    background-image: url(../../../assets/images/onboarding-lion3-bg.png);
                    background-position: center 105px;

                    h2 {
                        margin-bottom: 30px;
                    }
                }

                .details-wrapper {
                    height: 100%;
                    width: calc(100% + 77px);

                    // @media (max-width: 900px) {
                    //     width: 100%;
                    // }

                    h2 {
                        font-family: 'Oswald';
                        font-size: 30px;
                        font-weight: 600;
                        color: #C7322E;
                    }

                    .img-wrapper {
                        img {
                            @media (max-height: 900px) {
                                height: 400px;
                            }

                            @media (max-height: 700px) {
                                height: 300px;
                            }

                            // @media (max-width: 900px) {
                            //     width: 100%;
                            // }
                        }
                    }
                }
            }
        }
    }
}