.company-home-section {
    .page-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 30px;
    }

    .company-home-details-wrapper {
        display: flex;
        gap: 20px;

        .company-home-details {
            background: #FFFFFF;
            box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
            border-radius: 15px;
            padding: 26px;

            &.details-left {
                flex-basis: 42.6%;
                display: flex;
                align-items: center;

                &>* {
                    flex-basis: 50%;
                }

                .section-details-wrapper {
                    text-align: center;

                    &:first-of-type {
                        border-right: 2px solid #FED200;
                    }

                    .title {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 22px;
                        color: #878C90;
                    }

                    .number {
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 48px;
                        color: #091523;
                        margin-top: 16px;
                    }
                }
            }

            &.details-right {
                flex: 1;
                background: #091523;
                color: #FFFFFF;
                padding: 22px 16px 22px 22px;

                .title {
                    font-weight: 600;
                    font-size: 26px;
                    line-height: 36px;
                    font-family: 'Open Sans';
                }

                p {
                    font-family: 'Open Sans';
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    margin-top: 10px;
                    margin-bottom: 0;

                    .yellow {
                        color: #FED200;
                    }
                }
            }

        }
    }

}

.moreContainer {
    .pencil {
        h4 {
            color: #FF8721;
        }

        svg {
            fill: #FF8721;
        }
    }

    .toggle-button {
        padding: 0px 24px 20px 24px;
        gap: 10px;

        p {
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
        }
    }
}