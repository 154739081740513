.main-container {
    &.company-review-modal {
        .modal-dialog {
            width: 440px;
            max-width: 100%;

            .modal-content {

                .modal-body {
                    .review-container {
                        width: 320px;
                        max-width: 100%;
                        margin: 30px auto;

                        .review-data-wrapper,
                        .review-options {
                            background: #F9F9F9;
                            border-radius: 15px;
                            padding: 24px;

                            .name {
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 28px;
                                margin-bottom: 12px;
                            }

                            .number,
                            .email {
                                color: #4D5661;
                                font-weight: 400;
                            }

                            .number {
                                margin-bottom: 5px;

                                img {
                                    cursor: pointer;
                                }
                            }
                        }

                        .card-wrapper {
                            max-height: 570px;
                            overflow-y: auto;
                            &::-webkit-scrollbar {
                                display: none;
                            }
                        }

                        .review-options {
                            margin-top: 24px;
                            border: 2px solid #DDDFE0;

                            .review-actions {
                                margin-top: 12px;
                                border-top: 2px solid #DDDFE0;
                                padding: 18px 0 0;
                                display: flex;
                                align-items: center;

                                &>* {
                                    flex-basis: 50%;
                                    display: flex;
                                    justify-content: center;
                                }

                                .action {
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 25px;

                                    img {
                                        margin-right: 12px;
                                    }

                                    &.edit {
                                        color: #FF8721;
                                    }

                                    &.delete {
                                        color: #C7322E;
                                    }
                                }
                            }
                        }

                        .bottom-button-wrapper {
                            margin-top: 22px;

                            .outline-btn {
                                margin: 20px 0 34px;
                            }

                            .cancel {
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 25px;
                                color: #091523;
                            }
                        }
                    }
                }
            }
        }
    }
}