.breadcrumb-container {
    padding-left: 40px;
    padding-right: 40px;

    .heading {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #091523;
    }

    .btn-container {

        .plus-btn {
            max-width: 50px;
            padding: 15px 28px;
            border-radius: 16px;

            span {
                img {
                    margin-right: 0;
                }
            }
        }
    }
}

.popover-industry {
    background: #FFFFFF;
    border: 2px solid #DDDFE0;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 15px;
    padding: 24px;

    .add-new {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: #091523;
            margin-bottom: 0;
        }
    }

    .new-industry {
        display: flex;
        align-items: center;
        padding-top: 20px;
        cursor: pointer;
        gap: 20px;
        h4 {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #091523;
            margin-bottom: 0;
        }
    }
    .sub-industry {
        display: flex;
        align-items: center;
        padding-top: 20px;
        cursor: pointer;
        gap: 20px;
        h4 {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #091523;
            margin-bottom: 0;
        }
    }
}