.add-payment-card-details-wrapper {
    width: 100%;
    background: #091523;
    border-radius: 20px;
    padding: 24px 30px 24px 30px;
    color: #fff;

    .payment-cards-title {
        margin-bottom: 30px;

        .title-wrapper {
            width: 68%;

            h3 {
                font-weight: 600;
                font-size: 22px;
                line-height: 36px;
            }

            img {
                cursor: pointer;
            }
        }
    }

    .input-row {
        display: flex;
        gap: 10px;

        &>* {
            flex-basis: 50%;
        }
    }

}