.videos-layout{
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
    height: 100%;
    .top-search-bar-videos{
        padding: 33px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .company-heading-videos {
            font-family: "Oswald";
            color: #C7322E;
            font-size: 30px;
            line-height: 36px;
            font-weight: 700;

        }

    }
    .videos-section{
        margin: 20px 40px;
        padding: 24px;
        background-color: #FFFFFF;
        border-radius: 20px;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
        .spinner-wrapper{
            padding: 70px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .nodata-wrapper{
            padding: 70px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            font-weight: 500;
            color:  gray;
        }

        .video-header{
            padding: 8px;
            display: flex;
            h6{
                font-size: 14px;
                font-weight: 600;
                line-height: 22px;
                color: gray;
                cursor: pointer;
               
            }

            .active-style {
                position: relative;
                font-weight: 600;
                color: #091523;

                &::before {
                    content: ' ';
                    position: absolute;
                    bottom: 1px;
                    background: #FED200;
                    border-radius: 1px;
                    min-width: 65%;
                    height: 2px;
                }
            }
          

        }
    .video-card-header{
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        
     

     
    }

    }

}