.company-employees-section {
    .page-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    }

}

.moreContainer {
    .pencil {
        h4 {
            color: #FF8721;
        }

        svg {
            fill: #FF8721;
        }
    }

    .toggle-button {
        padding: 0px 24px 20px 24px;
        gap: 10px;

        p {
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
        }
    }
}