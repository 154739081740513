.homepage-section {
    display: flex;
    width: 100%;
    overflow: hidden;

    .main-section {
        width: 100%;
        min-width: calc(100% - 280px);

        @media (max-width: 1366px) {
            min-width: calc(100% - 220px);
        }

        .content-section {
            padding: 15px 40px 30px;
            overflow: auto;
            height: calc(100vh - 85px);
            max-height: 100%;

            @media (max-width: 1280px) {
                padding-left: 30px;
            }

            @media (max-width: 992px) {
                max-height: calc(100vh - 80px);
            }

            @media (max-width: 576px) {
                padding-left: 0;
            }

            &::-webkit-scrollbar {
                display: none;
            }

        }
    }

    .toggle-sidebar-icon {
        display: none;

        @media (max-width: 992px) {
            display: block;
            min-height: 100vh;
            background-color: #EFEFEF;
            width: 50px;
            text-align: center;
            padding-top: 30px;
            border-radius: 0 10px 10px 0;
        }

        @media (max-width: 768px) {
            width: 40px;

            img {
                height: auto;
                width: 25px;
            }
        }

        @media (max-width: 576px) {
            padding-top: 20px;
        }
    }
}