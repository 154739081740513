.feedback-layout {
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
    height: 100%;

    .feedback-top-search-bar {
        padding: 33px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .feedback-company-heading {
            font-family: "Oswald";
            color: #C7322E;
            font-size: 30px;
            line-height: 36px;
            font-weight: 700;

        }
    }

    .feedback-header {
        padding-left: 40px;
        padding-bottom: 30px;

        .feedback-head {
            font-size: 24px;
            font-weight: 700;
            line-height: 24px;
        }


    }



    .table-section-feedback {
        margin: 10px 40px;
        background-color: #FFFFFF;
        border-radius: 20px;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);

        .table-headers-feedback {
            padding: 8px;

            h6 {
                font-size: 18px;
                font-weight: 700;
                line-height: 28px;
                padding: 10px 10px 0px 10px;

            }

        }

        .feedback-view {
            padding-left: 20px;

            .email-feedback {

                .email {
                    font-size: 16px;
                    font-weight: 400px;
                    line-height: 24px;
                    color: #878C90;

                }

            }

            .feedback-heading {
                font-size: 20px;
                font-weight: 700px;
                line-height: 24px;
                color: black;


            }
        }



    }

}