.site-table-wrapper {
    margin: 30px 0;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    padding: 24px 0 0;

    .tag {
        height: 22px;
        min-width: 99px;
        color: #091523;
        background-color: #FED200;
        font-size: 12px;
        border-radius: 6px;
        font-weight: 700;
    }

    .site-table-head-wrapper {
        padding: 0 24px;

        .site-table-head {
            margin-bottom: 33px;

            .title {
                font-family: 'Open Sans';
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                color: #091523;
            }
        }

        .site-table-date-wrapper {
            margin-bottom: 20px;
            display: flex;
            gap: 16px;

            &>* {
                flex-basis: 50%;
            }
        }
    }

    .ant-table-wrapper {
        margin-left: 8px;
        margin-right: 8px;


        .ant-spin-nested-loading {
            .ant-spin-container {
                .ant-table {
                    font-family: 'Open Sans' !important;

                    .ant-table-container {
                        .ant-table-content {
                            .ant-table-thead {
                                border-radius: 15px !important;

                                .ant-table-cell {
                                    background-color: #DDDFE0 !important;
                                    padding: 17px 17px !important;
                                    font-size: 14px !important;
                                    color: #878C90 !important;
                                    font-weight: 600 !important;
                                    line-height: 22px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ant-table-wrapper {
        .ant-table-tbody {
            tr {
                td {
                    .ant-table-wrapper:only-child {
                        .ant-table {
                            margin-inline: -24px !important;
                        }
                    }
                }
            }
        }
    }



    .ant-table-wrapper {
        .ant-table-thead {
            tr {
                th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]) {
                    &::before {
                        background-color: transparent !important;
                    }
                }
            }
        }
    }

    .ant-table-expanded-row {
        .ant-table-cell {
            .ant-table-wrapper {
                .ant-spin-nested-loading {
                    .ant-spin-container {
                        .ant-table {
                            .ant-table-container {
                                .ant-table-content {
                                    .ant-table-thead {
                                        .ant-table-cell {
                                            padding: 7px 7px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ant-table-tbody {
        .ant-table-row {
            .ant-table-cell {
                border-bottom: none !important;
                font-size: 14px !important;
                color: #091523 !important;

                &:first-child {
                    font-weight: 600 !important;
                }
            }
        }
    }

    .ant-table-tbody {
        .ant-table-row-level-0 {
            .ant-table-cell {
                border-bottom: none !important;
                font-size: 14px !important;
                color: #091523 !important;

                &:nth-child(2) {
                    font-weight: 600 !important;
                }
            }
        }
    }

    .ant-table-wrapper {
        .ant-table-tbody {
            tr {
                td {
                    &:last-child {
                        text-align: start !important;
                    }
                }
            }
        }
    }

}