.landing-page-section {
    background-color: white;
    font-family: 'PT Serif';
    min-height: 100vh;
    position: relative;
    // background-image: url('../../../assets/images/landing-bg.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: hidden;

    @media (max-width: 1024px) {
        overflow: auto;
    }

    @media (max-width: 768px) {
        padding: 20px 0;
    }

    .image-style {
        width: 324.8px;
        height: 297.57px;
        position: relative;
        align-self: center
    }

    .login-form-container {
        align-self: center;
        background-color: #FFFFFF;
        padding: 29px 70px 34px;
        // box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
        // border-radius: 15px;
        width: 620px;
        max-width: 100%;

        // @media (max-height: 700px) {
        //     padding: 0 70px;
        // }
        @media (min-width: 501px) {
            padding: 0 70px;
        }
        @media (max-width: 500px) {
            padding: 0 20px;
        }

        .logo-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 177px;
            height: 165px;
            @media (max-width: 450px) {
                margin-bottom: 160px;
            }

            .landing-log {
                height: 165px;
            }

            .landing-text {
                padding-top: 40px;
            
            @media (max-width: 458px) {
                // padding: 0 30px;
                width: 80%;
            }
        }
        }

        .sign-in-btn {
            width: 100%;
        }
    }

    .landing-content-container {

        .landing-content-row,
        .login-content-row {
            position: relative;
            z-index: 9;
            padding: 0 50px;

            @media (max-width: 768px) {
                padding: 0 30px;
            }

            @media (max-width: 576px) {
                padding: 0 20px;
            }
        }

        position: relative;

        .landing-left-content {
            width: 75%;
            max-width: 100%;
            margin: 20px auto 0;

            @media (max-width:1366px) {
                margin: 10px auto 0;
            }

            @media (max-width: 1280px) {
                width: 85%;
            }

            @media (max-width: 1024px) {
                width: 90%;
            }

            .button-wrapper {
                display: flex;
                width: 100%;

                @media (max-width: 1440px) and (max-height: 900px) {
                    justify-content: unset;
                }

                @media (max-width: 576px) {
                    margin-top: 10px;
                }

                .board-btn {
                    max-width: 100%;
                    flex-basis: 48%;

                    @media (max-width: 768px) {
                        max-width: 180px;
                        padding: 6px 0;
                        font-size: 12px;
                    }

                    @media (max-width: 576px) {
                        max-width: 150px;
                        padding: 4px 0;
                        font-size: 10px;
                        height: 26px;
                        border-radius: 8px;
                    }

                    &.transparent-btn {
                        margin-left: 40px;

                        @media (max-width: 1440px) and (max-height: 900px) {
                            margin-left: 20px;
                        }

                        @media (max-width: 768px) {
                            margin-left: 12px;
                        }
                    }
                }

                a {
                    text-decoration: none;
                }
            }

            h1 {
                font-weight: 700;
                font-size: 64px;

                @media (max-width: 1600px) and (max-height: 700px) {
                    font-size: 36px;
                }

                @media (max-width: 1440px) and (max-height: 900px) {
                    font-size: 48px;
                    line-height: 1;
                }

                @media (max-width: 1024px) {
                    font-size: 40px;
                }

                @media (max-width: 768px) {
                    font-size: 34px;
                }

                @media (max-width: 576px) {
                    font-size: 28px;
                }
            }

            h2 {
                font-weight: 400;
                font-size: 24px;
                position: relative;

                @media (max-width: 1440px) and (max-height: 900px) {
                    margin-bottom: 0;
                    font-size: 18px;
                }

                @media (max-width: 1024px) {
                    font-size: 16px;
                }

                @media (max-width: 768px) {
                    font-size: 14px;
                }

                @media (max-width: 576px) {
                    font-size: 12px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 5px;
                    transform: translateY(-50%);
                    width: 30%;
                    height: 5px;
                    background-color: #F6B21A;
                    border-radius: 56px;

                    @media (max-width: 1440px) and (max-height: 900px) {
                        width: 35%;
                        right: 10px;
                    }

                    @media (max-width: 1024px) {
                        width: 20%;
                    }

                    @media (max-width: 768px) {
                        width: 25%;
                    }
                }
            }

            p {
                font-weight: 500;
                font-size: 16px;
                margin: 30px 0;
                font-family: 'Lato';

                @media (max-width: 1440px) and (max-height: 900px) {
                    margin: 18px 0;
                    font-size: 14px;
                }

                @media (max-width: 1024px) {
                    font-size: 12px;
                    margin: 12px 0;
                }

                @media (max-width: 768px) {
                    font-size: 11px;
                    line-height: 14px;
                }

                @media (max-width: 576px) {
                    font-size: 10px;
                    line-height: 12px;
                    margin: 8px 0;
                }
            }

            .browse {
                margin-top: 50px;

                @media (max-width: 1600px) and (max-height: 900px) {
                    margin-top: 15px;
                }

                @media (max-width: 768px) {
                    margin-top: 10px;
                }

                a {
                    color: #6F8BDF;
                    font-weight: 600;
                    font-size: 18px;
                    font-family: 'Lato';
                    padding-bottom: 2px;
                    text-decoration: none;
                    border-bottom: 1px solid #6F8BDF;

                    @media (max-width: 1600px) and (max-height: 900px) {
                        font-size: 14px;
                    }

                    @media (max-width: 768px) {
                        font-size: 12px;
                    }

                    @media (max-width: 576px) {
                        font-size: 10px;
                    }
                }
            }

        }

        .landing-right-content {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            @media (max-width: 768px) {
                margin: 10px 0;
            }

            img {
                @media (max-width:1920px) and (max-height: 1000px) {
                    width: 350px;
                }

                @media (max-width: 1600px) and (max-height: 900px) {
                    width: 300px;
                }

                @media (max-width: 1366px) {
                    width: 250px;
                }

                @media (max-width: 1024px) {
                    width: 220px;
                }

                @media (max-width: 768px) {
                    width: 150px;
                    margin: 10px 0;
                }
            }
        }
    }

    .landing-slider-section {
        width: 88%;
        padding-left: 50px;
        margin: 0 auto 0 0;

        @media (max-width:1440px) and (max-height: 900px) {
            padding-left: 40px;
            min-height: 200px;
        }

        @media (max-width:1366px) {
            width: 84%;
        }

        @media (max-width: 1280px) and (min-height: 900px) {
            width: 84%;
        }

        @media (max-width: 1024px) {
            width: 87%;
        }

        @media (max-width: 576px) {
            width: 90%;
        }

        @media (min-width:2000px) {
            padding-left: 70px;
        }

        .slider-container {
            height: 100%;

            .card-data {
                flex-basis: 14.28%;
                width: 100%;
                max-width: 100%;

                @media (max-width: 1280px) and (min-height: 900px) {
                    flex-basis: 19%;
                }

                @media (max-width: 1024px) {
                    flex-basis: 22%;
                }

                @media (max-width: 576px) {
                    flex-basis: 30%;
                }

                @media (max-width: 500px) {
                    flex-basis: 35%;
                    padding: 0 8px;
                }

                @media (max-width: 400px) {
                    flex-basis: 40%;
                }

                &:first-of-type {
                    .slider-card {
                        &::after {
                            background: linear-gradient(88.36deg, #FFFFFF 2.03%, rgba(255, 255, 255, 0) 243.12%);
                            position: absolute;
                            top: 0;
                            left: 0;
                            content: '';
                            height: 100%;
                            width: 100%;
                            z-index: 1;
                        }

                    }
                }
            }

            .slider-card {
                background-color: #A2A2A2;
                min-height: 181px;
                height: 100%;
                width: 100%;
                max-width: 141px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                z-index: 9;
                cursor: unset;

                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 16px;
                }

                @media (max-width: 1440px) and (max-height: 900px) {
                    min-height: 151px;
                    max-width: 111px;
                }

                @media (max-width:1366px) and (max-height:630px) {
                    min-height: 141px;
                }

                @media (max-width: 768px) {
                    min-height: unset;
                    max-height: 100px;
                    max-width: 85px;

                    img {
                        border-radius: 10px;
                    }
                }

                @media (min-width: 1920px) {
                    max-width: 181px;
                    min-height: 221px;
                }

                @media (min-width: 2000px) {
                    max-width: 221px;
                    min-height: 261px;
                }

                &.top-list {
                    background-color: transparent;
                    font-weight: 700;
                    color: #2B292D;
                    font-size: 24px;
                    font-family: 'Lato';
                    cursor: pointer;
                    height: 100%;

                    @media (max-width: 1440px) and (max-height: 900px) {
                        font-size: 20px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 14px;
                    }

                    @media (max-width: 768px) {
                        min-height: 100px;
                    }

                    @media (min-width: 1920px) {
                        min-height: 221px;
                    }

                    @media (min-width: 2000px) {
                        min-height: 261px;
                    }
                }
            }
        }
    }

    .landing-slider-wrapper {
        margin-top: -4%;
        position: relative;
        z-index: 9;
        padding: 25px 0;
        // height: 100%;

        @media (max-width: 1600px) and (max-height: 900px) {
            margin-top: -3%;
            padding: 15px 0;
        }

        @media (max-width: 1440px) and (max-height: 900px) {
            margin-top: -2%;
            padding: 15px 0;
        }

        @media (max-width:1366px) and (max-height:630px) {
            margin-top: -2%;
        }

        @media (max-width: 768px) {
            margin-top: 20px;
        }

        .slider-container {
            @media (max-width: 1280px) and (min-height: 900px) {
                padding: 0 17px;
            }

            @media (max-width:1024px) {
                padding: 0 17px;
            }

            .slider-card-row {
                position: relative;
                z-index: 1;

                @media (max-width:1280px) and (min-height: 900px) {
                    flex-wrap: nowrap;
                    overflow: scroll;
                }

                @media (max-width:1024px) {
                    flex-wrap: nowrap;
                    overflow: scroll;
                }

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(270.05deg, #FFFFFF 1.84%, rgba(255, 255, 255, 0) 75.29%);
                border-radius: 50px;
                transform: rotate(-180deg);
                // min-height: 245px;
                height: 100%;
                width: 100%;
                background-color: #EFEFEF;

                @media (max-width:1440px) and (max-height: 900px) {
                    border-radius: 30px;
                    // min-height: 200px;
                }

                @media (max-width: 768px) {
                    border-radius: 15px;
                }
            }
        }
    }
}