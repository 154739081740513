.subcription-layout {
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
    height: 100%;
    padding-bottom: 50px;

    .top-search-bar {
        padding: 33px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .company-heading {
            font-family: "Oswald";
            color: #C7322E;
            font-size: 30px;
            line-height: 36px;
            font-weight: 700;
        }
    }

    .subscriptions-card-wrapper {

        @media (max-width: 1200px) {
            flex-direction: column;
        }

        .EditplanBtn {
            .switch-label {
                display: none;
            }

            .board-btn {
                width: 100%;
            }
        }
        .slick-list{
            .slick-track{
                display: flex !important;
                gap: 10px;
            }
        }

        .slick-next {
            right: -14px !important;
            &::before {
                content: url('../../../assets/images/chevronforward.svg') !important;
            }
        }
        .slick-prev {
            display: block !important;
            left: -8px !important;
            transform: rotate(180deg);
            &::before {
                content: url('../../../assets/images/chevronforward.svg') !important;
            }
        }
    }

    .table-section-subscription {
        margin: 22px;
        background-color: #FFFFFF;
        border-radius: 20px;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    }
    .table-headers-subscription{
        padding: 8px;
        h6{
            font-size: 18px;
            font-weight: 700;
            line-height: 28px;
            padding: 10px 10px 0px 10px;
        }
    }
    .spinner-wrapper{
        padding: 70px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .nodata-wrapper{
        padding: 70px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        font-weight: 500;
        color:  gray;
    }
}