.search-wrapper {
    background: #DDDFE0;
    height: 48px;
    border-radius: 15px;
    display: flex;
    align-items: center;

    .search-field {
        display: flex;
        align-items: center;
        padding-left: 26px;
        width: 100%;
        .search-input {
            background-color: #DDDFE0;
            border: none;
            outline: none;
            width: 100%;
            padding-left: 10px;
            font-family: 'Open Sans';
            font-size: 14px;
            line-height: 19.07px;
            color: #4D5661;

            &::placeholder {
                font-family: 'Open Sans';
                font-size: 14px;
                line-height: 19px;
                color: #4D5661;
                mix-blend-mode: normal;
                opacity: 0.5;
            }
        }
    }
}