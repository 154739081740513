.video-wrapper-videos {
    border-radius: 15px;
    margin-bottom: 24px;

    .inner-container {
        background-color: #F9F9F9;
        padding-bottom: 28px;
        border-radius: 15px;

        .image-container {
            border-radius: 15px;
            position: relative;

            .thumbnail {
                border-radius: 15px 15px 0px 0px;
                width: 100%;
                max-height: 220px;
                object-fit: cover;

                @media (max-width: 1440px) {
                    max-height: 160px;
                }
            }

            .duration {
                position: absolute;
                background-color: rgba(9, 21, 35, 0.5);
                border-radius: 7px;
                padding: 6px 11px;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 10px;
                right: 24px;

                span {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 10px;
                    color: #FFFFFF;
                    letter-spacing: 0.5px
                }
            }

            .video-icon {
                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                top: 0;
                margin: auto;
                cursor: pointer;
            }
        }

        .video-details {
            margin-top: 23px;
            padding: 0 24px;
            width: 100%;
            display: flex;
            justify-content: space-between;


            .video-title {
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #091523;
                padding-bottom: 8px;
                margin-bottom: 0;
            }

            .video-info {
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #878C91;
                margin-bottom: 0;
            }

            .video-button {
                width: 100%;
                margin-bottom: 12px;
            }

            .button-container {
                .more-button {
                    min-width: 50px;
                    padding: 15px;
                    border-radius: 16px;

                    span {
                        img {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}
.ant-popover-inner-content{
    padding-top: 0px;
    @media (max-width: 768px) {
        padding-top: 0px;
    }
   
}


.popover-more-videos {
    background: #FFFFFF;
    border: 2px solid #DDDFE0;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 15px;
    padding: 24px;
    width: 100%;
    min-width: 320px;

    .add-new {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: #091523;
            margin-bottom: 0;
        }
    }

    .select-container {
        padding-top: 10px;
        width: 100%;
        max-width: 320px;
    }

    .edit-video {
        display: flex;
        align-items: center;
        padding-top: 0px;
        gap: 40px;

        .edit-video-content ,.duplicate-video-content{
            display: flex;
            gap: 10px;
            align-items: center;
            cursor: pointer;

            h4 {
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                color: #FF8721;
                margin-bottom: 0;
            }
        }

        .delete-video-content {
            display: flex;
            gap: 10px;
            align-items: center;
            cursor: pointer;

            h4 {
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                color: #C7322E;
                margin-bottom: 0;
            }
        }
    }
}