.modal-content {
    background: #FFFFFF !important;
    box-shadow: -40px 40px 80px -8px rgba(145, 158, 171, 0.24) !important;
    border-radius: 20px !important;
    border: none !important;
}

.modal-container {

    .close-icon-container {
        display: flex;
        justify-content: flex-end;

        .modal-close-icon {
            cursor: pointer;
        }
    }

    .header-container {
        .title {
            font-weight: 600;
            font-size: 22px;
            line-height: 36px;
            color: #091523;
            text-align: center;
        }
    }

    .input-container {
        margin: 30px 60px 10px 60px;

        .question-textarea{
            margin-top: 16px;
        }
        .add-btn{
            padding: 0px 16px;
            font-size: 18px;
            font-weight: 600;
            min-height: 42px;
            border-radius: 15px;
            margin-left: auto;
        }
    }

    .button-container {
        margin: 30px 60px;
    }
}

.modal-backdrop.show {
    background: rgba(9, 21, 35, 0.4) !important;
    opacity: 1 !important;
    backdrop-filter: blur(2px) !important;
}