body {
    max-height: 100vh;
    font-family: 'Open Sans';
    overflow-y: hidden;
}

.board-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #091523;
    background-color: #FED200;
    border-radius: 13px;
    width: auto;
    max-width: 100%;
    padding: 13px 40px;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    outline: none;
    box-shadow: unset !important;
    border-style: unset;
    font-family: 'Open Sans';
    min-height: 50px;

    &.full-btn {
        width: 100%;
    }

    &.half-btn {
        width: 50%;
    }

    &.outline-btn {
        border: 2px solid #091523;
        background-color: transparent;
        color: #091523;
    }

    &.yellow-outline-btn {
        border: 2px solid #FED200;
        background-color: transparent;
        color: #FED200;
    }

    &.delete-btn {
        background-color: #C7322E;
        color: #FFFFFF;
    }

    &.orange-btn {
        background-color: transparent;
        border: 2px solid #FF8721;
        color: #FF8721;
    }
    &.disable-btn {
        background-color: #DDDFE0;
        color: darkgray;
    }

    span {
        img {
            margin-right: 10px;
        }
    }

    &.right-icon {
        span {
            order: 1;
            margin-left: 10px;
        }
    }

    @media (max-width:1366px) {
        line-height: 18px;
        font-size: 16px;
        // min-height: 40px;
    }

    @media (max-width: 1024px) {
        // min-height: unset;
        font-size: 14px;
        padding: 8px 16px;
        border-radius: 10px;
    }

    @media (max-width:768px) {
        border-radius: 8px;
        padding: 6px 12px;
        font-weight: 500;
        // min-height: 34px;
    }
}

.input-field {
    position: relative;
}

input {

    &::placeholder {
        font-family: 'Lato';
    }

    &:focus {
        box-shadow: unset !important;
        border-color: unset !important;
        outline: none;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.opacity-1 {
    opacity: 1 !important;
}

.loader-container {
    width: 100%;
    min-height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-style {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.no-data {
    font-weight: 600;
    font-size: 32px;
}

.__PrivateStripeElement {

    top: 4px;
}

.checkbox-wrapper {
    position: relative;

    .styled-checkbox {
        position: absolute;
        opacity: 0;
        height: 24px;
        width: 24px;
        z-index: 1;
        cursor: pointer;
        left: 0px;

        &+label {
            position: relative;
            cursor: pointer;
            padding: 0;
            display: flex;
            align-items: center;
        }

        &+label:before {
            content: '';
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 24px;
            height: 24px;
            background: #fff;
            cursor: pointer;
            border-radius: 5px;
        }

        &:checked+label:before {
            background: #fff;
        }

        &:disabled+label {
            color: #b8b8b8;
            cursor: auto;
        }

        &:disabled+label:before {
            box-shadow: none;
            background: #ddd;
        }

        &:checked+label:after {
            content: '';
            position: absolute;
            left: 2px;
            top: 2px;
            width: 20px;
            height: 20px;
            background-image: url('../../assets/images/checkboxyellow.svg');
            background-position: center;
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }

    &.black {
        .styled-checkbox {
            &+label:before {
                background: #DDDFE0;
            }

            &:checked+label:before {
                background: #DDDFE0;
            }

            &:checked+label:after {
                background-image: url('../../assets/images/black-check-icon.svg');
            }
        }
    }
}

.toggle-select {
    display: flex;
    margin-bottom: 50px;
    padding-left: 15px;

    @media (max-width: 1366px) {
        margin-bottom: 30px;
    }

    .title {
        font-size: 16px;
        color: #A2A2A2;
        font-weight: 500;
        border-bottom: 4px solid #EFEFEF;
        max-width: 223px;
        width: 100%;
        text-align: center;
        font-family: 'Inter';
        transition: all 0.3s;
        cursor: pointer;
        padding-bottom: 8px;

        &.active {
            color: #6F8BDF;
            border-color: #6F8BDF;
        }

        @media (max-width: 1200px) {
            max-width: 200px;
        }
    }
}

.flex-align-center {
    display: flex;
    align-items: center;
}

.flex-justify-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-align-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-justify-between {
    display: flex;
    justify-content: space-between;
}

.flex-justify-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex-justify-evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.flex-justify-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.column-flex {
    display: flex;
    flex-direction: column;
}

.row-flex {
    display: flex;
    flex-direction: row;
}