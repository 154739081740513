.date-picker-wrapper {
    cursor: pointer;
    .ant-picker {
        height: 50px;
        background-color: #EFEFEF;
        color: #A2A2A2;
        border: none;
        width: 100%;
        font-family: 'Open Sans' !important;
        font-size: 16px;
        font-weight: 600;
        border-radius: 15px !important;

        @media (max-width: 1600px) and (max-height: 800px) {
            height: 46px;
        }

        @media (max-width:1366px) {
            font-size: 14px;
            height: 38px;
        }

        @media (max-width: 576px) {
            font-size: 11px;
            height: 35px;
        }

        .ant-picker-input {
            input {
                padding-left: 22px;
                position: relative;

                &::placeholder {
                    font-size: 14px;
                    color: #4D5661;
                    font-weight: 400;
                    font-family: 'Open Sans' !important;

                    @media (max-width:1366px) {
                        font-size: 14px !important;
                    }

                    @media (max-width:576px) {
                        font-size: 11px !important;
                    }
                }

                &:focus {
                    outline: none;
                    box-shadow: unset !important;
                    background-color: #efefef;
                }
            }
        }
    }

    .ant-picker-focused {
        box-shadow: unset !important;
    }
}