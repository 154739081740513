.settings-layout-left {
    height: 100%;

    .heading {
        color: #091523;
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
    }

    .settings-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding-bottom: 50px;

        .settings-options {
            padding-top: 20px;

            .settings-button-field {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 2px solid #DDDFE0;
                padding: 12px 13px;
                border-radius: 15px;
                margin-bottom: 20px;
                cursor: pointer;
                &.active{
                    border-color: #FED200;
                }

                .title {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;
                    color: #091523;
                    margin-bottom: 0;
                }
            }
        }
    }
}