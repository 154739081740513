.month-select {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: transparent !important;
        border: none !important;
    }

    .ant-select-selection-item {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        color: #091523;
    }

    ant-select-arrow {
        svg {
            fill: #091523;
        }
    }
}