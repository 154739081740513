.filter-date-picker{

   

    .ant-picker-panel{
        background-color: red !important;
    }
}

.ant-picker-dropdown {
    z-index: 9999 !important;
}