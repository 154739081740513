.payment-container-settings {
    background: #091523;
    padding: 24px 30px;
    border-radius: 20px;

    .logo-contain {
        align-items: center;
        text-align: center;

        .heading {
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            color: #ffffff;
            text-align: center;
            padding-top: 20px;
        }

        .plan-text {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: #ffffff;
            text-align: center;
            margin-bottom: 0;
        }
    }
}