.content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .video-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #4D5661;
        margin-top: 10px;
    }

    .question-number {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #FF8721;
        margin-top: 34px;
        margin-bottom: 10px;
    }

    .question {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #091523;
        max-width: 70%;
        text-align: center;
    }

    .answer-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #FF8721;
        text-align: center;
        margin: 24px 0px 10px 0px;
    }

    .answer {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #4D5661;
        text-align: center;
        margin-bottom: 30px;
        max-width: 70%;
    }

    .question-type {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #C7322E;
    }
}