.pagination-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 2px solid #DDDFE0;

    .pagination-wrapper {
        display: flex;
        gap: 20px;
        padding: 18px 20px;

        .rows-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }

        .pagination-select {
            outline: none;
            border: none;
            width: 40px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            background-color: transparent;
        }

        .button-wrapper {
            display: flex;
            gap: 27px;

            .icon-back {
                transform: rotate(90deg);
                cursor: pointer;
            }

            .icon-next {
                transform: rotate(90deg);
                cursor: pointer;
            }

            .disable {
                opacity: 0.4;
            }
            .homeButton-wrapper img:first-child{
                margin-right: -15px;
               
            }
            .endButton-wrapper img:nth-child(2) {
                margin-left: -15px;
               
            }
        }
    }
}