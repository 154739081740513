.subscription-review-section {
    background-image: url(../../../assets/images/onboarding-bg.png);
    height: 100vh;
    background-color: #F9F9F9;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-height: 900px) {
        padding: 20px 0;
    }

    .main-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }

    .subscription-review-container {
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
        border-radius: 15px;
        background-color: white;
        padding: 24px 70px 34px 70px;

        .logo-contain {
            align-items: center;
            text-align: center;

            .heading {
                font-size: 24px;
                font-weight: 600;
                line-height: 34px;
                color: #091523;
                text-align: center;
                padding-top: 20px;
            }

        }

        .review-container {
            padding: 26px 22px;
            border: 2px solid #DDDFE0;
            border-radius: 15px;
            margin: 0 auto;

            .bold-text {
                font-weight: 700;
                font-size: 65px;
                color: #091523;

                @media(max-width:1024px){
                    font-weight: 700;
                    font-size: 42px;
                    color: #091523;
                    min-width: 70px;
                    padding: 5px;
                }
               
            }

            .simple-text {
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
                color: #091523;
                margin-left: 10px;
            }

            .dollar-text {
                position: absolute;
                top: 0;
                font-size: 25px;
                font-weight: 500;
                left: -6px;
            }
        }
        .board-btn {
            max-width: 480px;
            width: 100%;
        }
    }
}