.feedback-details-wrapper {
    width: 100%;
    background: #091523;
    border-radius: 20px;
    padding: 24px 30px 24px 30px;
    color: #fff;

    .feedback-title {
        margin-bottom: 30px;


        h3 {
            font-weight: 600;
            font-size: 22px;
            line-height: 36px;
        }

        img {
            cursor: pointer;
        }
    }

    .input-container {
        .button-wrapper {
            margin-top: 30px;
            

            .yellow-outline-btn {
                margin-top: 20px;
            }
        }
        .text-area-black{
            color: #fff;
        }
    }

}