.users-layout {
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
    height: 100%;


    .top-search-bar {
        padding: 33px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .company-heading {
            font-family: "Oswald";
            color: #C7322E;
            font-size: 30px;
            line-height: 36px;
            font-weight: 700;

        }
    }

    .table-section {
        background-color: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
        border-radius: 15px;
        margin: 10px 40px;


        .table-heading {
            font-family: 'Open Sans';
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: #091523;
            padding: 24px;
        }

        .select-search-row {
            display: flex;
            align-items: center;
            padding: 21px 24px;
            gap: 16px;
        }
    }

}
.spinner-wrapper{
    padding: 70px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nodata-wrapper{
    padding: 70px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 500;
    color:  gray;
}