.video-wrapper-industries {
    border-radius: 15px;
    margin-bottom: 24px;
    margin-right: 10px;

    .inner-container {
        background-color: #F9F9F9;
        padding-bottom: 15px;
        border-radius: 15px;

        .image-container {
            border-radius: 15px;
            position: relative;

            .thumbnail {
                border-radius: 15px 15px 0px 0px;
                width: 100%;
                max-height: 220px;
                object-fit: cover;
            }

            .duration {
                position: absolute;
                background-color: rgba(9, 21, 35, 0.5);
                border-radius: 7px;
                padding: 6px 11px;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 10px;
                right: 24px;

                span {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 10px;
                    color: #FFFFFF;
                    letter-spacing: 0.5px
                }
            }
            .video-icon{
                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                top: 0;
                margin: auto;
                cursor: pointer;
            }
        }

        .video-details {
            margin-top: 23px;
            padding: 0 24px;
            width: 100%;

            .video-title {
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #091523;
                margin-bottom: 5px;
            }

            .video-info {
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #878C91;
                margin-bottom: 24px;
            }

            .video-button {
                width: 100%;
                margin-bottom: 12px;
            }
        }
    }
}