.breadcrumb-container-videos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 40px;

    .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #091523;
        margin-bottom: 0;
    }

    .breadcrumb-data-videos {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .breadcrumb-list {

        ul {
            display: flex;
            align-items: center;
            gap: 30px;
            padding-left: 0;

            :first-child {
                list-style-type: none !important;
            }

            li {
                &::marker {
                    color: #878C90 !important;
                    font-size: 0.7rem !important;
                }
                a {
                    color: #4D5661;
                    text-decoration: none;
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }
    }
   .board-btn{
    justify-content: center;
      
        font-size: 18px;
        font-weight: 600px;
    }
}