.industries-layout {
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
    height: 100%;


    .top-search-bar {
        padding: 33px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .company-heading {
            font-family: "Oswald";
            color: #C7322E;
            font-size: 30px;
            line-height: 36px;
            font-weight: 700;

        }
    }

    .industries-section {
        padding-left: 40px;
        padding-right: 40px;
        position: relative;
        // margin-top: 90px;

        .industries-container {
            background: #FFFFFF;
            box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
            border-radius: 15px;
            padding: 24px;
            top: -39px;
            position: relative;

            .categories-parent {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 26px;

                .categories {
                    display: flex;
                    align-items: center;
                    gap: 40px;
                    overflow-x: auto;
                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .category-text {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 22px;
                        color: #878C91;
                        cursor: pointer;
                        white-space: nowrap;
                    }

                    .selected-category {
                        color: #091523;
                        position: relative;
                        cursor: pointer;

                        &::before {
                            content: ' ';
                            position: absolute;
                            bottom: -8px;
                            background: #FED200;
                            border-radius: 1px;
                            min-width: 100%;
                            height: 2px;
                        }
                    }
                }

                .edit-button {
                    max-width: 50px;
                    padding: 15px 28px;
                    border-radius: 16px;

                    span {
                        img {
                            margin-right: 0;
                        }
                    }
                }
            }

            .videos-container {
                .videos-section {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 15px;

                    .videos-list {
                        ul {
                            display: flex;
                            align-items: center;
                            gap: 30px;
                            padding-left: 0;
                            margin-bottom: 0;

                            :first-child {
                                list-style-type: none !important;

                                a {
                                    font-weight: 600 !important;
                                    color: #4D5661 !important;
                                }
                            }

                            li {
                                &::marker {
                                    color: #878C90 !important;
                                    font-size: 0.7rem !important;
                                }

                                a {
                                    color: #4D5661;
                                    text-decoration: none;
                                    font-size: 14px;
                                    line-height: 22px;
                                }
                            }
                        }
                    }

                    .total-videos {
                        span {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 22px;
                            color: #4D5661;
                        }
                    }
                    
                }
            }
            .video-style {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                
                .videos-style-section {
                    display: flex;
                    flex-basis: 33.33%; /* Each item takes up 1/3 of the container width */
                    box-sizing: border-box;
                }
            }
        }
    }
}
.edit-video {
    display: flex;
    align-items: center;
    // padding-top: 20px;
    gap: 10px;

    .edit-video-content {
        display: flex;
        // gap: 10px;
        align-items: center;
        cursor: pointer;
    }

    .delete-video-content {
        display: flex;
        // gap: 10px;
        align-items: center;
        cursor: pointer;
    }
}
