.input-field {
    position: relative;
    margin-bottom: 12px;
    width: 100%;

    label {
        display: none;

        &.error-message {
            display: block;
            color: red;
            font-size: 14px;
            margin: 6px 0;
            line-height: 16px;
            margin-bottom: 10px;
            opacity: 0;
            min-height: 16px;
            max-width: 90%;

            // @media (max-width:1680px) {
            //     line-height: 14px;
            //     margin: 6px 0 !important;
            // }

            // @media (max-width: 1366px) {
            //     margin-top: 3px;
            //     min-height: 14px;
            //     font-size: 12px;
            // }

            // @media (max-width:1366px) and (max-height:630px) {
            //     // font-size: 10px;
            //     margin-bottom: 6px;
            // }

            // @media(max-width: 576px) {
            //     // font-size: 10px;
            //     margin: 4px 0 6px;
            // }
        }
    }

    input {
        background-color: #DDDFE0;
        border-radius: 15px;
        height: 50px;
        max-height: 100%;
        border: unset;
        padding: 16px 22px;
        font-family: 'Open Sans';


        &::placeholder {
            font-family: 'Open Sans' !important;
            color: #4D5661;
            font-size: 14px;
            line-height: 19px;
            opacity: 0.5;
        }

        &:focus {
            outline: none;
            box-shadow: unset !important;
            background-color: #DDDFE0;
            border: 1px solid #FED200 !important;
            transition: ease-in-out, box-shadow .15s ease-in-out !important;
        }

        &.black-input {
            background-color: #4D5661;
            border-radius: 15px;
            height: 50px;
            max-height: 100%;
            border: unset;
            padding: 16px 22px;
            font-family: 'Open Sans';
            width: 100%;
            color: #FFFFFF;

            &::placeholder {
                font-family: 'Open Sans' !important;
                color: #FFFFFF !important;
                font-size: 14px;
                line-height: 19px;
                opacity: 0.5;
            }

            &:focus {
                outline: none;
                box-shadow: unset !important;
                background-color: #4D5661;
                border: 1px solid #FED200 !important;
                transition: ease-in-out, box-shadow .15s ease-in-out !important;
            }
        }
    }



    .right-icon,
    .left-icon {
        position: absolute;
        top: 14px;
        right: 19px;
        cursor: pointer;
        margin-top: -1px;

        @media (max-width: 1600px) and (max-height: 800px) {
            height: 46px;
            top: 10px;
        }

        @media (max-width:1366px) {
            top: 14px;

            img {
                height: 20px;
            }
        }

        @media(max-width: 576px) {
            // top: 5px;

            img {
                height: 10px;
            }
        }
    }

    .left-icon {
        left: 15px;
        right: unset;
        cursor: unset;
    }

    .cvv-icon {
        height: 10px;

        @media (max-width:1366px) {
            height: 8px !important;
        }
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        left: 13px;
        position: absolute;
        // background-image: url('../../assets/images/calendar.svg');
    }
}

.form-control {
    margin-bottom: 6px;
}

.opacity-1 {
    opacity: 1 !important;
}