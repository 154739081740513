.login-container {
    background-image: url(../../../assets/images/loginbg.png);
    height: 100vh;
    background-color: #F9F9F9;
    background-repeat: no-repeat;
    background-size: cover;

    .main-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }


    .login-form-container {
        background-color: #FFFFFF;
        padding: 29px 70px 34px;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
        border-radius: 15px;
        width: 620px;
        max-width: 100%;
        max-height: calc(100vh - 80px);
        overflow: auto;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .logo-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .heading-safety {
                font-family: "Oswald";
                color: #C7322E;
                font-size: 24px;
                line-height: 26px;
                font-weight: 700;
                // padding-top: 20px;
                padding-bottom: 10px;
            }

            .heading-sign-in {
                color: #091523;
                font-size: 26px;
                line-height: 36px;
                font-weight: 600;
                padding-bottom: 20px;
            }

            .heading-signup {
                font-family: "Open Sans";
                color: #878C90;
                font-size: 16px;
                line-height: 22px;
                font-weight: 600;
                opacity: 0.3;
                cursor: pointer;
                border-bottom-color: #878C90;
                border-bottom-style: solid;
                text-align: center;
            }
.width52{
    width: 52%;
}
.width48{
    width: 48%;
}
            .header-borders {
                font-family: "Open Sans";
                color: #FF8721;
                font-size: 16px;
                line-height: 22px;
                font-weight: 600;
                // padding-bottom: 30px;
                cursor: pointer;
                border-bottom-color: #FF8721;
                border-bottom-style: solid;
                text-align: center;
            }

        }

        .sign-in-btn {
            width: 100%;
        }

        .email-style {
            font-family: 'Open sans';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            margin-left: 10px;

        }

        .input-field {
            margin-bottom: 0px
        }

        .text-color {
            color: #FF8721;
            margin: 0 2px;
        }

        .term-condition {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            margin-left: 10px;
            display: inline-block;
        }

        .check-box {
            width: 20px;
            height: 20px;
            margin-left: 10px;

        }

        .forgot-text {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #FF8721;
            cursor: pointer;
            text-align: right;
        }


    }

    &.error-message {
        display: block;
        color: red;
        font-size: 14px;
        margin: 6px 0;
        line-height: 16px;
        margin-bottom: 10px;
        opacity: 0;
        min-height: 16px;
        max-width: 90%;

        // @media (max-width:1680px) {
        //     // font-size: 12px;
        //     line-height: 14px;
        //     margin: 6px 0;
        // }

        // @media (max-width: 1366px) {
        //     margin-top: 3px;
        //     min-height: 14px;
        //     font-size: 12px;
        // }

        // @media (max-width:1366px) and (max-height:630px) {
        //     // font-size: 10px;
        //     margin-bottom: 6px;
        // }

        // @media(max-width: 576px) {
        //     // font-size: 10px;
        //     margin: 4px 0 6px;
        // }
    }

    .opacity-1 {
        opacity: 1 !important;
    }

    // .error-message {
    //     @media (max-width: 1366px) {
    //         font-size: 12px;
    //     }
    // }
}