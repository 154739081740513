.ant-space {
    width: 100% !important;
    display: flex;
    flex-direction: column;

    .ant-space-item {
        width: 100% !important;
        height: 48px;

        .ant-select {

            width: 100% !important;

            .ant-select-selector {
                height: 48px !important;
                background-color: #DDDFE0 !important;
                border-radius: 15px !important;
                padding: 7px 13px 12px 24px !important;

                &:focus {
                    border-color: #FED200 !important;
                }

                .ant-select-selection-search {
                    .ant-select-selection-search-input {
                        height: 48px !important;
                    }
                }

                .ant-select-selection-placeholder {
                    color: #4D5661 !important;
                    opacity: 0.5 !important;
                    font-size: 14px !important;
                font-weight: 400 !important;
                font-family: "Open Sans" !important;

                }

                .ant-select-selection-item {
                    color: #212529 !important;
                    font-size: 16px !important;
                font-weight: 400 !important;
                font-family: "Open Sans" !important;
                    // opacity: 0.5 !important;
                }
            }

            .ant-select-arrow {
                position: absolute;
                top: 70%;
                span {
                    svg {
                        fill: #091523 !important;
                    }
                }
            }
        }
    }
}

.ant-select-dropdown {
    z-index: 9999 !important;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: #FED200 !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-1vtf12y).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: #FED200 !important;
}

.error-message {
    color: red;
    font-size: 14px;
    margin: 0px !important;
    line-height: 16px;
    margin-bottom: 13px;
    min-height: 16px;
    max-width: 90%;
    opacity: 1;
}

.opacity-0 {
    opacity: 0;
}