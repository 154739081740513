.upload-file-wrapper {
    .ant-upload {
        width: 100%;

        button {
            background-color: #DDDFE0;
            border-radius: 15px;
            height: 50px;
            max-height: 100%;
            border: unset;
            padding: 16px 22px;
            font-family: 'Open Sans';
            width: 100%;
            color: #4D5661;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                color: #4D5661;
            }

            svg {
                opacity: 0.7;
            }

            span {
                margin-left: 14px;
            }
        }
    }
}