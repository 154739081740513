.feedback-card-header{
    padding-left: 40px;
    text-align: center;
    display: flex;
    padding-bottom: 30px;

    img{
        cursor: pointer;
    }

    .feedback-card-head{
        padding-left: 17px;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 0;
    }

}

    h6{
    padding: 15px;
    font-size: 24px;
    font-weight: 700px;
    line-height: 24px;
    
}

.table-section-feedback-card{
    margin: 10px 40px;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
 
    @media (max-width:768px) {
        padding: 0px 30px;
    }


.table-headers-feedback-card{
padding: 8px;
h6{
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    padding: 10px 10px 0px 10px;

}
}



.feedback-view{
 
    padding-left: 20px;
   .header-feedback{
    padding: 24px ;
    .feedback-head{
        font-size: 20px;
        font-weight: 700px;
        line-height: 24px;
        color: black;
    }
   
    .email{
        font-size: 16px;
        font-weight: 400px;
        line-height: 24px;
        color: #878C90;

    }

    .feedback-header-view{
        font-size: 20px;
        font-weight: 700px;
        line-height: 24px;
    }
    .feedback-description{
        font-size: 16px;
        font-weight: 400px;
        line-height: 24px;
        color: #878C90;

    }


   }
   .feedback-heading{
    font-size: 20px;
    font-weight: 700px;
    line-height: 24px;
    color: black;
    

   }
   .feedback-card-message{
    justify-content: space-between;
    display: flex;
   }
}
}
