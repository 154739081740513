.leaderboard-table-section {
    .ant-table-wrapper {
        margin-left: 8px;
        margin-right: 8px;

        .ant-spin-nested-loading {
            .ant-spin-container {
                .ant-table {
                    font-family: 'Open Sans' !important;

                    .ant-table-container {
                        .ant-table-content {
                            .ant-table-thead {
                                border-radius: 15px !important;

                                .ant-table-cell {
                                    background-color: #DDDFE0 !important;
                                    padding: 17px 17px !important;
                                    font-size: 14px !important;
                                    color: #878C90 !important;
                                    font-weight: 600 !important;
                                    line-height: 22px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-table-wrapper {
        .ant-table-row-expand-icon-collapsed {
            &::before {
                content: url('../../../../../assets/images/chevronDown.svg') !important;
                transform: rotate(0deg) !important;
                position: absolute;
                top: 0 !important;
                inset-inline-end: 0px !important;
                inset-inline-start: -2px !important;
                background: none !important;
                transition: transform 0.3s ease-out;
            }
        }
    }

    .ant-table-wrapper {
        .ant-table-row-expand-icon {
            border: none !important;
            background: none !important;
        }
    
    }

    .ant-table-wrapper {
        .ant-table-row-expand-icon-collapsed {
            &::after {
                content: ' ' !important;
                transform: rotate(0deg) !important;
                width: 0 !important;
            }
        }
    }



.ant-table-wrapper {
    .ant-table-row-expand-icon-expanded {
        &::before {
            content: url('../../../../../assets/images/chevronDown.svg') !important;
            transform: rotate(180deg) !important;
            position: absolute;
            top: 17px !important;
            inset-inline-end: -2px !important;
            inset-inline-start: 0px !important;
            background: none !important;
            transition: transform 0.3s ease-out;
        }
    }
}

.ant-table-wrapper {
    .ant-table-row-expand-icon-expanded {
        &::after {
            content: ' ' !important;
            transform: rotate(0deg) !important;
            width: 0 !important;
        }
    }
}

.ant-table-wrapper {
    .ant-table-tbody {
        tr {
            td {
                .ant-table-wrapper:only-child {
                    .ant-table {
                        margin-inline: -24px !important;
                    }
                }
            }
        }
    }
}

.ant-table-wrapper {
    .ant-table-thead {
        tr {
            th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]) {
                &::before {
                    background-color: transparent !important;
                }
            }
        }
    }
}
.ant-table-wrapper {
    .ant-table-tbody {
        tr {
            td {
                &:last-child {
                    text-align: end !important;
                }
            }
        }
    }
}

.ant-table-expanded-row {
    .ant-table-cell {
        .ant-table-wrapper {
            .ant-spin-nested-loading {
                .ant-spin-container {
                    .ant-table {
                        .ant-table-container {
                            .ant-table-content {
                                .ant-table-thead {
                                    .ant-table-cell {
                                        padding: 7px 7px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ant-table-tbody {
    .ant-table-row {
        .ant-table-cell {
            border-bottom: none !important;
            font-size: 14px !important;
            color: #091523 !important;

            &:first-child {
                font-weight: 600 !important;
            }
        }
    }
}
.ant-table-tbody {
    .ant-table-row-level-0 {
        .ant-table-cell {
            border-bottom: none !important;
            font-size: 14px !important;
            color: #091523 !important;

            &:nth-child(2) {
                font-weight: 600 !important;
            }
        }
    }
}

.leaderboard-pill {
    display: flex;
    justify-content: end;

    span {
        background-color: #FED200;
        padding: 2px 20px;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        color: #091523;
        text-align: center;
        max-width: 134px;
        border-radius: 22.22px;
    }
}

.ranking {
    span {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 1px;
        color: #FF8721;
    }
}
}