.modal-content {
    background: #FFFFFF !important;
    box-shadow: -40px 40px 80px -8px rgba(145, 158, 171, 0.24) !important;
    border-radius: 20px !important;
    border: none !important;
}

.modal-container {

    .close-icon-container {
        display: flex;
        justify-content: flex-end;

        .modal-close-icon {
            cursor: pointer;
        }
    }

    .header-container {
        .user-title {
            font-weight: 600;
            font-size: 22px;
            line-height: 36px;
            color: #091523;
            text-align: center;
        }
    }

    .text-container {
        margin: 30px 60px;

        p {
            font-size: 16px;
            line-height: 24px;
            color: #4D5661;
            font-weight: 600;
            text-align: center;

            .bold-text {
                font-size: 16px;
                line-height: 24px;
                color: #091523;
                font-weight: 600;
                text-align: center;
            }
        }
    }

    .button-container {
        margin: 30px 60px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .dropdown-select-container {
        margin: 30px 60px;
    }
}

.modal-backdrop.show {
    background: rgba(9, 21, 35, 0.4) !important;
    opacity: 1 !important;
    backdrop-filter: blur(2px) !important;
}