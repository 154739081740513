
.terms-container-full {
    background-color: #091523;
    padding: 24px 30px;
    height: 100vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
        display: none;
    }

    .heading-text {
        font-size: 22px;
        color: #ffffff;
        font-weight: 600;
        line-height: 36px;
        text-align: center;
    }

    .updated-text {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        color: #FF8721;
    }

    .yellow-text {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        color: #FED200;
    }
}