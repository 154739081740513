.main-container {

    &.company-jobsite-modal {
        .modal-dialog {
            width: 440px;
            max-width: 100%;

            .modal-content {

                .modal-body {
                    .input-container {
                        width: 320px;
                        max-width: 100%;
                        margin: 30px auto;

                        .button-wrapper {
                            margin-bottom: 24px;
                        }

                        .upload-file-wrapper {
                            margin-bottom: 24px;
                        }

                        h3 {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 22px;
                            color: #091523;
                            margin-bottom: 10px;
                        }

                        .bottom-button-wrapper {
                            margin-top: 22px;

                            .outline-btn {
                                margin: 20px 0 34px;
                            }

                            .cancel {
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 25px;
                                color: #091523;
                            }
                        }
                    }
                }
            }
        }
    }
}