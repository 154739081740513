.payment-cards-wrapper {
    width: 100%;
    background: #091523;
    border-radius: 20px;
    padding: 24px 30px 24px 30px;
    color: #fff;

    .payment-cards-title {
        margin-bottom: 30px;

        .title-wrapper {
            width: 60%;

            h3 {
                font-weight: 600;
                font-size: 22px;
                line-height: 36px;
            }

            img {
                cursor: pointer;
            }
        }
    }

    .payment-details-card {
        margin-bottom: 24px;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:not(:last-of-type) {
            .button-wrapper {
                .delete-btn {
                    margin-bottom: 48px;
                }
            }
        }

        .payment-card {
            background: #4D5661;
            border-radius: 15px;
            padding: 24px 36px 24px 24px;
            margin-bottom: 20px;
            cursor: pointer;

            h3 {
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 12px;
            }

            .account-number {
                color: #DDDFE0;
                margin-bottom: 12px;
            }

            .date {
                background: #FED200;
                border-radius: 22px;
                width: fit-content;
                max-width: 100px;
                height: 29px;
                font-weight: 800;
                font-size: 12px;
                line-height: 16px;
                color: #091523;
                padding: 8px 17px;
            }
        }

        .button-wrapper {
            .delete-btn {
                margin-top: 20px;
            }
        }
    }
}