.subscription-payment-section {
    background-image: url(../../../assets/images/onboarding-bg.png);
    height: 100vh;
    background-color: #F9F9F9;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-height: 900px) {
        padding: 20px 0;
    }

    .main-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }

    .payment-container {
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
        border-radius: 15px;
        background-color: white;
        padding: 24px 70px 34px 70px;


        .logo-contain {
            align-items: center;
            text-align: center;

            .heading {
                font-size: 24px;
                font-weight: 600;
                line-height: 34px;
                color: #091523;
                text-align: center;
                padding-top: 20px;
            }

            .plan-text {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                color: #4D5661;
                text-align: center;
                margin-bottom: 0;
            }
        }

        .input-contain {
            padding-top: 40px;
        }

        .input-row {
            display: flex;
            gap: 10px;

            >* {
                flex-basis: 50%;
            }
        }
    }

}