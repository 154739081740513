.change-password-container {
    background-color: #091523;
    padding: 24px 30px;
    border-radius: 20px;

    .heading-text {
        font-size: 22px;
        color: #ffffff;
        font-weight: 600;
        line-height: 36px;
        text-align: center;
    }

    .input-container {
        padding-top: 20px;
    }
}