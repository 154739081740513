.title-container {
    display: flex;
    background-color: #DDDFE0;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0px;
    border-radius: 15px;
    flex-direction: column;



    .selected-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 15px;
        p {
            margin: 0px;
            color: black;
            opacity: 1;
        }
        img{
            cursor: pointer;
        }
    }

    .options-container {
        width: 100%;
      padding: 15px 6px 0px 6px;

        .option {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 11px 7px 11px 16px;
            cursor: pointer;

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #878C91;
                margin: 0px;
            }

            // input {
            //     height: 24px !important;
            //     width: 24px !important;
            //     border-radius: 5px !important;
            //     outline: none !important;
            //     border: none !important;
                
                

            // }

            // input[type="checkbox"] {
            //     -webkit-appearance: none; /* Remove default appearance */
            //     -moz-appearance: none;
            //     appearance: none;
            //     width: 20px;
            //     height: 20px;
            //     border: 2px solid #fff;
            //     background: #fff;
            //     border-radius: 3px;
            //     outline: none;
            //     transition: all 0.3s ease-in-out;
            //     cursor: pointer;
            //   }
        }

        .option-active{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 11px 7px 11px 16px;
            background-color: #FED200;
            border-radius: 10px;

            p {
                font-weight: 600 !important;
                font-size: 14px;
                line-height: 19px;
                color: #091523 !important;
                margin: 0px;
            }

            // input {
            //     height: 24px !important;
            //     width: 24px !important;
            //     border-radius: 5px !important;
            //     outline: none !important;
            //     border: none !important;
            // }

            // input[type="checkbox"] {
            //     -webkit-appearance: none; /* Remove default appearance */
            //     -moz-appearance: none;
            //     appearance: none;
            //     width: 20px;
            //     height: 20px;
            //     border: 2px solid #fff;
            //     border-radius: 3px;
            //     background-color: #fff;
            //     outline: none;
            //     transition: all 0.3s ease-in-out;
            //     cursor: pointer;
            //   }
            //   input[type="checkbox"]:checked {
            //     border-color: #fff !important; /* Change color when checked */
            //     background-color: #fff;
            //   }
            //   input[type="checkbox"]:checked::before {
            //     content: ''; /* Add a checkmark */
            //     background-image: url('../../../../../assets/images/checkboxyellow.svg');
            //     display: block;
            //     text-align: center;
            //     line-height: 18px;
            //     font-size: 14px;
            //     color: #fff;
            //   }
        }
    }


}