.analytics-card-row {
    display: flex;
    gap: 24px;
    padding-left: 40px;
}

.analytics-card {
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    padding: 24px;
    border-radius: 15px;
    max-height: 166px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .numbers {
        .app {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #091523;
        }

        .total {
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
            color: #091523;
        }

        .bold {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #091523;
            padding-left: 10px;
        }

        .medium {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #878C91;
        }
    }
}

.large-graphs {
    display: flex;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
    gap: 15px;

    .download-charts-container {
        background: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
        border-radius: 15px;
        padding: 24px;
        margin-top: 24px;

        h1 {
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: #091523;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #4D5661;

        }
    }

    .piechart-container {
        background: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
        border-radius: 15px;
        padding: 24px;
        margin-top: 24px;

        h1 {
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: #091523;
        }
    }
}

.analytics-table {
    padding: 30px 20px;
}