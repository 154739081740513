.header-section {
	padding: 20px 40px;
	background-color: #fff;
	width: 100%;
	max-height: 117px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	@media (max-width:1366px) {
		padding: 20px 35px 20px 20px;
	}

	@media (max-width: 1280px) {
		padding: 20px;
	}

	@media (max-width: 576px) {
		padding: 15px 10px;
	}

	.logo {
		font-family: "Oswald";
		color: #C7322E;
		font-size: 30px;
		line-height: 36px;
		font-weight: 700;
	}

	.settings {
		cursor: pointer;
	}
}