.login-container {
    background-image: url(../../../assets/images/loginbg.png);
    height:100vh;
    background-color: #F9F9F9;
    background-repeat: no-repeat;
    background-size: cover;

    .main-container {
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        height: 100%;
    }
    .forgot-pass{
        text-align: right;
    }


    .login-form-container {
        background-color: #FFFFFF;
        padding: 29px 70px 34px;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
        border-radius: 15px;
        width: 620px;
        max-width: 100%;

        .logo-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            .heading-safety {
                font-family: "Oswald";
                color: #C7322E;
                font-size: 24px;
                line-height: 34px;
                font-weight: 700;
                padding-top: 20px;
                padding-bottom: 15px;
            }
            .heading-sign-in {
                color: #091523;
                font-size: 26px;
                line-height: 36px;
                font-weight: 600;
                padding-bottom: 30px;
            }
        }
        .sign-in-btn {
            width: 100%;
        }
    }
}