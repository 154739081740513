.upper-container {
    h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #091523;
    }

    p {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #4D5661;
        margin-bottom: 30px;

        span {
            color: #FF8721;
        }
    }
}

.calender-container {
    background-color: white;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 12px;
    .calender-spinner-wrapper{
        padding: 70px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-picker-calendar {
        .ant-picker-content {
            thead {
                tr {
                    th {

                        padding: 16px !important;
                        border-top: 1px solid #DDDFE0 !important;
                        font-size: 14px !important;
                        text-align: center !important;
                        color: #FF8721 !important;
                        font-weight: 600 !important;
                        line-height: 22px !important;
                    }
                }
            }
        }
    }

    .calendar-footer {
        padding: 19px 24px;
        border-top: 1px solid #DDDFE0;
        border-radius: 0 0 12px 12px;

        .footer-title {
            color: #FF8721;
            font-weight: 700;
            margin-bottom: 10px;
        }

        .footer-text {
            color: #4D5661;
            line-height: 22px;
        }
    }
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
    padding: 0;
}

.ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date {
    margin: 0 !important;
    border-right: 1px solid #DDDFE0 !important;
}


.ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-today {
    background-color: #FFFFFF !important;
}


.ant-picker-calendar.ant-picker-calendar-full .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar.ant-picker-calendar-full .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar.ant-picker-calendar-full .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar.ant-picker-calendar-full .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: #091523 !important;
}

.ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-today {
    border-color: #DDDFE0 !important;
}

.ant-picker-calendar.ant-picker-calendar-full .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar.ant-picker-calendar-full .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-calendar-date-today {
    background-color: #FFFFFF !important; 

    &:has(.table-cell-content) {
        background-color: #FF872170 !important;
    }
}

.table-cell-content {
    height: calc(100% - 14px);
    display: flex;
    align-items: center;
    width: 100%;

    p {
        font-weight: 600;
        padding: 2px 4px;
        font-size: 14px;
        line-height: 22px;
        color: #091523;
        border: 1px solid #FF8721;
        border-radius: 4px;
        margin-bottom: 0;
        width: 100%;
    }
}

.ant-picker-calendar .ant-picker-calendar-header {
    padding: 20px 24px;
}

.ant-picker-calendar {
    border-radius: 12px 12px 0 0;
}

.ant-picker-calendar-date {
    &:has(.table-cell-content) {
        background-color: #FF872170 !important;
    }
}