.textArea-Container{
    margin-top: 16px;
    display: flex;
    background-color: #DDDFE0 ;
    justify-content: space-between;
    border-radius: 15px;
    margin-bottom: 16px;

    textarea{
        &:focus{
            border: unset !important;
        }
    }

    .textarea-Question-option{
        width: 100%;
        border-color: none;
        outline: none;
        
    }

    .radio-btn{
        width: 25px;
        margin-right: 10px;
       
        color: orange;
    }

}