.card-container {
    cursor: pointer;

    &.card-container-basic {
        background-color: #FFFFFF;
        padding: 27px 24px;
        border-radius: 15px;
        border: 2px solid #DDDFE0;
        margin-bottom: 20px;

        .plan {
            font-size: 11.11px;
            color: #4D5661;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }

        .recommended {
            background-color: #FF8721;
            font-size: 10px;
            padding: 8px 17px;
            font-weight: 800;
            line-height: 13.5px;
            border-radius: 22px;
            text-transform: uppercase;
        }

        .businessdays {
            padding-top: 24px;

            h2 {
                color: #FF8721;
                font-size: 24px;
                font-weight: 600;
            }
        }

        .employeePerMonth {
            display: flex;

            .doller {
                font-size: 24px;
                font-weight: 500;
                line-height: 29px;
                color: #091523;
            }

            .employee-eight {
                display: flex;
                text-align: center;
                align-items: center;

            }

            .price {
                font-size: 64px;
                font-weight: 700;
                line-height: 88px;
                color: #091523;
            }

            .price-description {
                padding-left: 10px;
                color: #091523;
                font-weight: 500;
                font-size: 15.5px;
                line-height: 18px;
            }
        }

        .description {            
            max-height: 100px;
            overflow-y: auto;
            height: 100px;
            font-size: 15.5556px;
            line-height: 19px;
            color: #878C90;

            ul {
                display: flex;
                flex-direction: column;
                gap: 10px;

            }
        }

        .EditplanBtn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 24px;

            .editBtn {
                padding: 14px 70px;
            }

            .basic-btn {
                border: 2px solid #FF8721;
                background-color: transparent;
                color: #FF8721;
            }
        }
    }

    &.card-container-recommended {
        background-color: #091523;
        padding: 27px 24px;
        border-radius: 15px;

        .plan {
            font-size: 11.11px;
            opacity: 0.6;
            color: #FFFFFF;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }

        .recommended {
            background-color: #FED200;
            font-size: 10px;
            padding: 8px 17px;
            font-weight: 800;
            line-height: 13.5px;
            border-radius: 22px;
            text-transform: uppercase;
        }

        .businessdays {
            padding-top: 24px;

            h2 {
                color: #FED200;
                font-size: 24px;
                font-weight: 600;
            }
        }

        .employeePerMonth {
            display: flex;

            .doller {
                font-size: 24px;
                font-weight: 500;
                line-height: 29px;
                color: #FFFFFF;
            }

            .employee-eight {
                display: flex;
                text-align: center;
                align-items: center;

            }

            .price {
                font-size: 64px;
                font-weight: 700;
                line-height: 88px;
                color: #FFFFFF;
            }

            .price-description {
                padding-left: 10px;
                color: #FFFFFF;
                font-weight: 500;
                font-size: 15.5px;
                line-height: 18px;
            }
        }

        .description {
            font-size: 15.5556px;
            line-height: 19px;
            color: #DDDFE0;
            max-height: 100px;
            overflow-y: auto;
            height: 100px;

            ul {
                display: flex;
                flex-direction: column;
                gap: 10px;

            }
        }

        .EditplanBtn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 24px;

            .editBtn {
                padding: 14px 70px;
            }

            .recommended-btn {
                background-color: #FED200;
            }
        }
    }

}

.planHeader {
    justify-content: space-between;
    display: flex;
    text-align: center;
    align-items: center;

}

.plan {
    font-size: 11.11px;
    opacity: 0.6;
    color: #FFFFFF;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}