.slick-slider {
    .slick-next, .slick-prev{
        background-color: #C7322E;
        width: 25px;
        height: 25px;
        border-radius: 15px;
        z-index: 9999;
    }
    .slick-next {
        right: -14px !important;
        &::before {
            content: url('../../../../../assets/images/chevronforward.svg') !important;
        }
    }
    .slick-prev {
        display: none !important;
    }
}