.modal-content {
    background: #FFFFFF !important;
    box-shadow: -40px 40px 80px -8px rgba(145, 158, 171, 0.24) !important;
    border-radius: 20px !important;
    border: none !important;
}

.main-container {
    .modal-container {

        .close-icon-container {
            display: flex;
            justify-content: flex-end;

            .modal-close-icon {
                cursor: pointer;
            }
        }

        .header-container {
            .user-title {
                font-weight: 600;
                font-size: 22px;
                line-height: 36px;
                color: #091523;
                text-align: center;
            }
        }

        .input-container {
            margin: 30px 60px;
        }

        .button-container {
            margin: 30px 60px;
        }

    }

    &.company-employee-modal {
        .modal-dialog {
            width: 440px;
            max-width: 100%;

            .modal-content {

                .modal-body {
                    .input-container {
                        width: 320px;
                        max-width: 100%;
                        margin: 30px auto;

                        .button-wrapper {
                            margin-bottom: 24px;
                        }

                        .upload-file-wrapper {
                            margin-bottom: 24px;
                        }

                        h3 {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 22px;
                            color: #091523;
                            margin-bottom: 10px;
                        }

                        .bottom-button-wrapper {
                            margin-top: 22px;

                            .outline-btn {
                                margin: 20px 0 34px;
                            }

                            .cancel {
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 25px;
                                color: #091523;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}


.modal-backdrop.show {
    background: rgba(9, 21, 35, 0.4) !important;
    opacity: 1 !important;
    backdrop-filter: blur(2px) !important;
}

.upload-template-wrapper {
    padding-bottom: 10px;
    .upload-file {
        position: relative;
        background-color: #DDDFE0;
        height: 50px;
        width: 100%;
        max-width: 100%;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 15px;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
        p {
            margin: 0;
            padding-top: 4px;
            padding-left: 10px;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #4D5661;
            mix-blend-mode: normal;
            opacity: 0.5;
        }
        img {
            mix-blend-mode: normal;
            opacity: 0.5;
        }
    }
}