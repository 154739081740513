.table-section {
    .ant-table-wrapper {
        margin-left: 8px;
        margin-right: 8px;

        .ant-spin-nested-loading {
            .ant-spin-container {
                .ant-table {
                    font-family: 'Open Sans' !important;

                    .ant-table-container {
                        .ant-table-content {
                            .ant-table-thead {
                                border-radius: 15px !important;

                                .ant-table-cell {
                                    background-color: #DDDFE0 !important;
                                    padding: 17px 17px !important;
                                    font-size: 14px !important;
                                    color: #878C90 !important;
                                    font-weight: 600 !important;
                                    line-height: 22px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.ant-table-wrapper {
    .ant-table-row-expand-icon-collapsed {
        &::before {
            content: url('../../../../../assets/images/chevronDown.svg') !important;
            transform: rotate(0deg) !important;
            position: absolute;
            top: 0 !important;
            inset-inline-end: 0px !important;
            inset-inline-start: -2px !important;
            background: none !important;
            transition: transform 0.3s ease-out;
        }
    }
}

.ant-table-wrapper {
    .ant-table-row-expand-icon {
        border: none !important;
        background: none !important;
    }

}

.ant-table-wrapper {
    .ant-table-row-expand-icon-collapsed {
        &::after {
            content: ' ' !important;
            transform: rotate(0deg) !important;
            width: 0 !important;
        }
    }
}

.ant-table-wrapper {
    .ant-table-row-expand-icon-expanded {
        &::before {
            content: url('../../../../../assets/images/chevronDown.svg') !important;
            transform: rotate(180deg) !important;
            position: absolute;
            top: 17px !important;
            inset-inline-end: -2px !important;
            inset-inline-start: 0px !important;
            background: none !important;
            transition: transform 0.3s ease-out;
        }
    }
}

.ant-table-wrapper {
    .ant-table-row-expand-icon-expanded {
        &::after {
            content: ' ' !important;
            transform: rotate(0deg) !important;
            width: 0 !important;
        }
    }
}

.pill {

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #091523;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .pill-text {
        margin-bottom: 0px;
        background-color: #FED200;
        padding: 1px 10px;
        border-radius: 6px;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        color: #091523;
        text-align: center;
    }
}



.ant-table-wrapper {
    .ant-table-tbody {
        tr {
            td {
                .ant-table-wrapper:only-child {
                    .ant-table {
                        margin-inline: -24px !important;
                    }
                }
            }
        }
    }
}



.ant-table-wrapper {
    .ant-table-thead {
        tr {
            th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]) {
                &::before {
                    background-color: transparent !important;
                }
            }
        }
    }
}

.ant-table-wrapper {
    .ant-table-tbody {
        tr {
            td {
                &:last-child {
                    text-align: end !important;
                }
            }
        }
    }
}

.ant-table-expanded-row {
    .ant-table-cell {
        .ant-table-wrapper {
            .ant-spin-nested-loading {
                .ant-spin-container {
                    .ant-table {
                        .ant-table-container {
                            .ant-table-content {
                                .ant-table-thead {
                                    .ant-table-cell {
                                        padding: 7px 7px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ant-table-tbody {
    .ant-table-row {
        .ant-table-cell {
            border-bottom: none !important;
            font-size: 14px !important;
            color: #091523 !important;

            &:first-child {
                font-weight: 600 !important;
            }
        }
    }
}

.ant-table-tbody {
    .ant-table-row-level-0 {
        .ant-table-cell {
            border-bottom: none !important;
            font-size: 14px !important;
            color: #091523 !important;

            &:nth-child(2) {
                font-weight: 600 !important;
            }
        }
    }
}

.ant-popover {
    .ant-popover-inner {
        background-color: transparent !important;
        box-shadow: unset !important;
        padding: 0 !important;
    }
}

.ant-popover-placement-bottomRight {
    .ant-popover-arrow {
        display: none !important;
    }
}

.ant-popover-placement-topRight {
    .ant-popover-arrow {
        display: none !important;
    }
}


.moreContainer {
    z-index: 2;
    width: 236px;
    background: #FFFFFF;
    border: 2px solid #DDDFE0;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 15px;

    .more {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 27px 24px 23px 24px;

        h4 {
            font-size: 18px;
            font-weight: 600;
            line-height: 24.51px;
            color: #091523;
            margin-bottom: 0;
        }
    }


    .flag {
        display: flex;
        align-items: center;
        padding: 0px 24px 20px 24px;
        gap: 10px;
        cursor: pointer;

        h4 {
            font-size: 18px;
            font-weight: 600;
            line-height: 24.5px;
            color: #091523;
            margin-bottom: 0;
        }
    }

    .pencil {
        display: flex;
        align-items: center;
        padding: 0px 24px 20px 24px;
        gap: 10px;
        cursor: pointer;

        h4 {
            font-size: 18px;
            font-weight: 600;
            line-height: 24.5px;
            color: #091523;
            margin-bottom: 0;
        }
    }

    .delete {
        display: flex;
        align-items: center;
        padding: 0px 24px 20px 24px;
        gap: 10px;
        cursor: pointer;

        h4 {
            font-size: 18px;
            font-weight: 600;
            line-height: 24.5px;
            color: #C7322E;
            margin-bottom: 0;
        }
    }
}

.flag-icon-none {
    display: none;
}

.flag-icon {
    display: block;
}