.onboarding-form-section {
    background-image: url(../../../../../assets/images/loginbg.png);
    height: 100vh;
    background-color: #F9F9F9;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-height: 900px) {
        padding: 20px 0;
    }

    .main-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }


    .onboarding-form-container {
        background-color: #FFFFFF;
        padding: 29px 70px 34px;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
        border-radius: 15px;
        width: 620px;
        max-width: 100%;

        @media (max-height: 900px) {
            max-height: calc(100vh - 100px);
            overflow-y: auto;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .logo-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .heading-safety {
                font-family: 'Open Sans';
                color: #091523;
                font-size: 24px;
                line-height: 34px;
                font-weight: 700;
                padding-top: 20px;
                padding-bottom: 15px;
                text-align: center;
                margin-bottom: 0;

                .red {
                    font-family: "Oswald";
                    color: #C7322E;
                    display: block;
                }
            }

            .sub-heading {
                color: #4D5661;
                font-weight: 600;
                text-align: center;
            }
        }

        .form-field-wrapper {
            margin-top: 35px;

            .input-field {
                margin-bottom: 8px;
            }

            .input-row {
                display: flex;
                gap: 10px;

                >* {
                    flex-basis: 50%;
                }
            }

            .sign-in-btn {
                width: 100%;

                span {
                    order: 1;
                    margin-left: 15px;
                }
            }
        }

    }
}