

.table-section-subscription {
    .ant-table-wrapper{
       background-color: #FFFFFF;
        .ant-spin-nested-loading {
            padding: 20px !important;
            .ant-spin-container{
                .ant-table{
                    .ant-table-container{
                      
                        .ant-table-content{
                          
                            .ant-table-thead{
                                .ant-table-cell{
                                background-color: #DDDFE0 !important;
                                color: #878C90 !important;
                                }
                            }
                            .ant-table-tbody{
                                .ant-table-row{
                                    .ant-table-cell{
                                        font-size: 14px !important;
                                        font-weight: 600px !important;
                                        line-height: 22px !important;
                                        color: #091523 !important;

                                    }
                                }
                            }

                        }
                    }
                }
            }
    
        }
        .pill {
            // background-color: #FED200;
            padding: 1px 8px;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
            color: #091523;
            text-align: center;
            max-width: 100px;
        }
        .pill-basic {
            background-color: #FF8721;
            
        }.pill-other{
            background-color: #FED200;
        }
        
        
        
    
    }
  
    
    .ant-table-wrapper {



        .ant-table-tbody {
            tr {
                td {
                    &:last-child {
                        text-align: end !important;
                    }
                }
            }
        }
    } 

    .ant-popover  {
        .ant-popover-inner {
            background-color: transparent !important;
            box-shadow: unset !important;
            padding: 0 !important;
        }
    }
    
    .ant-popover-placement-bottomRight {
        .ant-popover-arrow {
            display: none !important;
        }
    }
    .ant-popover-placement-topRight {
        .ant-popover-arrow {
            display: none !important;
        }
    } 
}

.more-container-subcription{
    z-index: 2;
    width: 236px;
    background: #FFFFFF;
    border: 2px solid #DDDFE0;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 15px;

    .more-Subcription{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 27px 24px 23px 24px;

        h4 {
            font-size: 18px;
            font-weight: 600;
            line-height: 24.51px;
            color: #091523;
            margin-bottom: 0;
        }
    }


    .flag {
        display: flex;
        align-items: center;
        padding: 0px 24px 20px 24px;
        gap: 10px;
        cursor: pointer;

        h4 {
            font-size: 18px;
            font-weight: 600;
            line-height: 24.5px;
            color: #091523;
            margin-bottom: 0;
        }
    }

    .pencil {
        display: flex;
        align-items: center;
        padding: 0px 24px 20px 24px;
        gap: 10px;
        cursor: pointer;

        h4 {
            font-size: 18px;
            font-weight: 600;
            line-height: 24.5px;
            color: #091523;
            margin-bottom: 0;
        }
    }
    .delete {
        display: flex;
        align-items: center;
        padding: 0px 24px 20px 24px;
        gap: 10px;
        cursor: pointer;

        h4 {
            font-size: 18px;
            font-weight: 600;
            line-height: 24.5px;
            color: #C7322E;
            margin-bottom: 0;
        }
    }
}   



